import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      exibirFiltro: true,
      exibirTotalizadores: true,
      breadcrumbs: [],
    };
  },
  computed: {
    ...mapGetters('filtros', [
      'filtroLiquidacaoAcaoNivel3',
    ]),
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
  },
  watch: {
    filtroLiquidacaoAcaoNivel3(val) {
      if (val && this.breadcrumbs[2]) {
        this.breadcrumbs[2].text = this.montarTextoNivel3(val);
      }
    },
  },
  methods: {
    ...mapActions('filtros', [
      'setFiltroLiquidacaoAcaoNivel2',
      'setFiltroLiquidacaoAcaoNivel3',
    ]),
    iniciaBreadcrumbs(to) {
      if (to.params.rotaAlterada) {
        this.breadcrumbs = [{
          text: to.params.descricaoRotaAlterada,
          to: to.params.rota,
          disabled: true,
          nivel: 1,
        }];
      } else {
        this.breadcrumbs = [{
          text: this.$tc('label.pagamento', 1),
          to: 'pagamento',
          disabled: true,
          nivel: 1,
        }];
      }
    },
    navegarBreadcrumbs(item) {
      if (item.disabled) {
        return;
      }
      const visaoFornecedor = this.$router.currentRoute.name === 'nivel2Recebimento'
        || this.$router.currentRoute.name === 'detalharRecebimento'
        || this.$router.currentRoute.name === 'detalharPagamentoFornecedor';

      const visaoCliente = this.$router.currentRoute.name === 'nivel3PagamentoCliente'
        || this.$router.currentRoute.name === 'nivel2PagamentoClienteMassivo'
        || this.$router.currentRoute.name === 'detalharPagamentoCliente'
        || this.$router.currentRoute.name === 'detalharPagamentoClienteMassivo';

      let visao = 'Status';
      if (visaoCliente) {
        visao = 'Cliente';
      } else if (visaoFornecedor) {
        visao = 'Fornecedor';
      }

      const params = {
        visao,
      };

      let query;

      if (item.status) {
        params.status = item.status;
      }

      if (item.idAcaoPrevia) {
        params.idAcaoPrevia = item.idAcaoPrevia;
      }

      if (item.idCliente) {
        params.idCliente = item.idCliente;
        query = { origem: item.textoAnterior ? item.textoAnterior : item.text };
      }

      if (item.idFornecedor) {
        params.idFornecedor = item.idFornecedor;
        query = { origem: item.textoAnterior ? item.textoAnterior : item.text };
      }

      console.log({ name: item.to, params, query });

      this.$router.push({ name: item.to, params, query });
    },
    alteracaoRota(to) {
      this.iniciaBreadcrumbs(to);
      this.exibirFiltro = true;
      this.exibirTotalizadores = true;
      if (to.name === 'listarPagamentosNivel2Status') {
        this.adicionarItemBreadcrumbsStatus(to);
      } else if (to.name === 'listarPagamentosNivel3Status') {
        this.adicionarItemBreadcrumbsStatusNivel3(to);
      } else if (to.name === 'listarPagamentosNivel3StatusMassivo') {
        this.adicionarItemBreadcrumbsStatusNivel3(to);
      } else if (to.name === 'liquidacaoEmLista') {
        this.exibirTotalizadores = false;
        this.adicionarItemBreadcrumbsLista(to);
      } else if (to.name === 'nivel2PagamentoCliente') {
        this.adicionarItemBreadcrumbsCliente(to);
      } else if (to.name === 'nivel3PagamentoCliente') {
        this.adicionarItemBreadcrumbsCliente(to);
      } else if (to.name === 'nivel2PagamentoFornecedor') {
        this.adicionarItemBreadcrumbsFornecedor(to);
      } else if (to.name === 'nivel2PagamentoClienteMassivo') {
        this.adicionarItemBreadcrumbsClienteMassivo(to);
      } else if (to.name === 'nivel2PagamentoFornecedorMassivo') {
        this.adicionarItemBreadcrumbsFornecedor(to);
      } else if (to.name === 'nivel2Recebimento') {
        this.adicionarItemBreadcrumbsFornecedor(to);
      } else if (to.name === 'recebimento' || to.name === 'pagamento') {
        this.removerItemBreadcrumbs();
      } else {
        this.exibirFiltro = false;
        this.exibirTotalizadores = false;
        this.abrirFiltro = false;
        if (to.name === 'detalharPagamentoStatus') {
          this.adicionarItemBreadcrumbsDetalhesStatus(to);
        } else if (to.name === 'detalharPagamentoStatusMassivo') {
          this.adicionarItemBreadcrumbsDetalhesStatusMassivo(to);
        } else if (to.name === 'detalharPagamentoCliente') {
          this.adicionarItemBreadcrumbsDetalhesCliente(to);
        } else if (to.name === 'detalharPagamentoClienteMassivo') {
          this.adicionarItemBreadcrumbsDetalhesClienteMassivo(to);
        } else if (to.name === 'detalharPagamentoFornecedor') {
          this.adicionarItemBreadcrumbsDetalhesFornecedor(to);
        } else if (to.name === 'detalharPagamentoFornecedorMassivo') {
          this.adicionarItemBreadcrumbsDetalhesFornecedorMassivo(to);
        } else if (to.name === 'detalharRecebimento') {
          this.adicionarItemBreadcrumbsDetalhesFornecedor(to);
        } else if (to.name === 'detalharPagamentoOrigem') {
          this.habilitarItemBreadcrumbsRaiz();
        }
      }
    },
    habilitarItemBreadcrumbsRaiz() {
      this.breadcrumbs[0].disabled = false;
    },
    adicionarItemBreadcrumbsStatus(to) {
      this.breadcrumbs[0].disabled = false;

      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: this.$tc(`status_entidade_pagamento.${to.params.status.toLowerCase()}`, 1),
          status: to.params.status,
          to: to.name,
          disabled: true,
          nivel: 2,
        },
      ];
      this.setFiltroLiquidacaoAcaoNivel3(null);
    },
    adicionarItemBreadcrumbsStatusNivel3(to) {
      this.breadcrumbs[0].disabled = false;

      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: this.$tc(`status_entidade_pagamento.${to.params.status.toLowerCase()}`, 1)
            .concat(' / ').concat(to.params.idAcaoPrevia ? to.params.idAcaoPrevia : to.params.idAcao),
          status: to.params.status,
          idAcaoPrevia: to.params.idAcaoPrevia,
          to: 'listarPagamentosNivel2Status',
          disabled: false,
          nivel: 2,
        },
      ];
      this.setFiltroLiquidacaoAcaoNivel3(null);
    },
    adicionarItemBreadcrumbsLista(to) {
      this.breadcrumbs[0].disabled = false;

      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: to.query.origem,
          to: to.name,
          disabled: true,
          nivel: 2,
        },
      ];
      this.setFiltroLiquidacaoAcaoNivel3(null);
    },
    adicionarItemBreadcrumbsFornecedor(to) {
      this.breadcrumbs[0].disabled = false;

      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: to.query.origem,
          idFornecedor: to.params.idFornecedor,
          to: to.name,
          disabled: true,
          nivel: 2,
        },
      ];
      this.setFiltroLiquidacaoAcaoNivel3(null);
    },
    adicionarItemBreadcrumbsCliente(to) {
      this.breadcrumbs[0].disabled = false;

      to.query.origem = to.query.origem.replaceAll(`- ${to.params.idAcaoPrevia}`, '');

      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: to.query.origem.concat(to.params.idAcao ? ` - ${to.params.idAcao}` : ''),
          idCliente: to.params.idCliente,
          to: to.name,
          disabled: true,
          nivel: 2,
        },
      ];
      this.setFiltroLiquidacaoAcaoNivel3(null);
    },
    adicionarItemBreadcrumbsClienteMassivo(to) {
      this.breadcrumbs[0].disabled = false;

      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: to.query.origem.concat(` - ${to.params.idAcaoPrevia}`),
          idCliente: to.params.idCliente,
          idAcaoPrevia: to.params.idAcaoPrevia,
          to: 'nivel2PagamentoCliente',
          disabled: false,
          nivel: 2,
        },
      ];
      this.setFiltroLiquidacaoAcaoNivel3(null);
    },
    adicionarItemBreadcrumbsDetalhesStatus(to) {
      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: this.$tc(`status_entidade_pagamento.${to.params.status.toLowerCase()}`, 1),
          status: to.params.status,
          to: 'listarPagamentosNivel2Status',
          disabled: false,
          nivel: 2,
        },
        {
          text: this.montarTextoNivel3(to.params),
          status: to.params.status,
          idAcao: to.params.idAcao,
          to: 'listarPagamentosNivel3Status',
          disabled: false,
          nivel: 3,
        },
      ];
      this.breadcrumbs[0].disabled = false;
    },
    adicionarItemBreadcrumbsDetalhesStatusMassivo(to) {
      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: this.$tc(`status_entidade_pagamento.${to.params.status.toLowerCase()}`, 1),
          status: to.params.status,
          to: 'listarPagamentosNivel2Status',
          disabled: false,
          nivel: 2,
        },
        {
          text: this.montarTextoNivel3(to.params),
          status: to.params.status,
          idAcaoPrevia: to.params.idAcaoPrevia,
          to: 'listarPagamentosNivel3StatusMassivo',
          disabled: false,
          nivel: 3,
        },
      ];
      this.breadcrumbs[0].disabled = false;
    },
    adicionarItemBreadcrumbsDetalhesCliente(to) {
      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: to.query.origem ? to.query.origem : this.breadcrumbs[1].text,
          idCliente: to.params.idCliente,
          to: 'nivel2PagamentoCliente',
          disabled: false,
          nivel: 2,
        },
        {
          text: this.montarTextoNivel3(to.params),
          idCliente: to.params.idCliente,
          idAcao: to.params.idAcao,
          textoAnterior: to.query.origem ? to.query.origem : this.breadcrumbs[1].text,
          to: 'nivel3PagamentoCliente',
          disabled: false,
          nivel: 3,
        },
      ];
      this.breadcrumbs[0].disabled = false;
    },
    adicionarItemBreadcrumbsDetalhesFornecedor(to) {
      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: to.query.origem ? to.query.origem : this.breadcrumbs[1].text,
          idFornecedor: to.params.idFornecedor,
          to: 'nivel2Recebimento',
          disabled: false,
          nivel: 2,
        },
        {
          text: this.montarTextoNivel3(to.params),
          to: to.name,
          disabled: true,
          nivel: 3,
        },
      ];
      this.breadcrumbs[0].disabled = false;
    },
    adicionarItemBreadcrumbsDetalhesClienteMassivo(to) {
      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: to.query.origem ? to.query.origem : this.breadcrumbs[1].text,
          idCliente: to.params.idCliente,
          idAcaoPrevia: to.params.idAcaoPrevia,
          to: 'nivel2PagamentoCliente',
          disabled: false,
          nivel: 2,
        },
        {
          text: this.montarTextoNivel3(to.params),
          idCliente: to.params.idCliente,
          idAcaoPrevia: to.params.idAcaoPrevia,
          textoAnterior: to.query.origem ? to.query.origem : this.breadcrumbs[1].text,
          to: 'nivel2PagamentoClienteMassivo',
          disabled: false,
          nivel: 3,
        },
      ];
      this.breadcrumbs[0].disabled = false;
    },
    adicionarItemBreadcrumbsDetalhesFornecedorMassivo(to) {
      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: to.query.origem ? to.query.origem : this.breadcrumbs[1].text,
          idFornecedor: to.params.idFornecedor,
          to: 'nivel2Recebimento',
          disabled: false,
          nivel: 2,
        },
        {
          text: this.montarTextoNivel3(to.params),
          idFornecedor: to.params.idFornecedor,
          idAcaoPrevia: to.params.idAcaoPrevia,
          to: 'nivel2Recebimento',
          disabled: false,
          nivel: 3,
        },
      ];
      this.breadcrumbs[0].disabled = false;
    },
    montarTextoNivel3(params) {
      let texto = '';

      if (params.idAcaoPrevia) {
        texto = texto.concat(params.idAcaoPrevia).concat(' - ');
      }

      if (params.idAcao) {
        texto = texto.concat(params.idAcao).concat(' - ');
      }
      if (params.codAcao) {
        texto = texto.concat(params.codAcao).concat(' - ');
      }
      if (params.cod_acao) {
        texto = texto.concat(params.cod_acao).concat(' - ');
      }

      if (params.codRecebimento) {
        texto = texto.concat(params.codRecebimento);
      }
      if (params.id) {
        texto = texto.concat(params.id);
      }
      return texto;
    },
    removerItemBreadcrumbs() {
      this.breadcrumbs[0].disabled = true;
      this.breadcrumbs = [this.breadcrumbs[0]];
      this.setFiltroLiquidacaoAcaoNivel2(null);
      this.setFiltroLiquidacaoAcaoNivel3(null);
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.alteracaoRota(to);
    next();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.alteracaoRota(to);
    });
  },
};
